import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiComponentsModule } from '@mwe/ui/components';
import { TermsContentCardComponent } from '@ikp/components/terms-of-use/terms-content-card/terms-content-card.component';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { IPopupModel } from '@mwe/models';
import { IkpAuthService } from '@ikp/service/auth/ikp-auth.service';
import { UserService } from '@ikp/service/user/user.service';
import { UserStatus } from '@ikp/model/user-status-info.model';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-ikp-terms-of-use',
  standalone: true,
  imports: [CommonModule, UiComponentsModule, TermsContentCardComponent],
  templateUrl: './terms-of-use.component.html',
})
export class TermsOfUseComponent implements OnInit {
  private popupService = inject(IkpPopupService);
  private authService = inject(IkpAuthService);
  private keycloakService = inject(KeycloakService);
  userService = inject(UserService);

  ngOnInit() {
    if (!this.userService.userStatusInfo() && this.keycloakService.isLoggedIn()) {
      this.userService.getUserStatusInfo().then();
    }
  }

  onAccept() {
    this.userService.registerUser().then();
  }

  onDecline() {
    const errorPopupModel: IPopupModel = {
      id: 'decline-terms-of-use',
      titleKey: 'termsOfUse.declinePopup.title',
      messageKey: 'termsOfUse.declinePopup.message',
      showSubmitButton: true,
      submitButtonKey: 'termsOfUse.declinePopup.cancelBtn',
      showCancelButton: true,
      cancelButtonKey: 'termsOfUse.declinePopup.declineBtn',
      iconColorClass: 'yellow',
      iconTypeClass: 'fas fa-solid fa-siren-on fs-5 pt-3',
    };
    this.popupService.handleGenericPopup(errorPopupModel);
    this.popupService.events().subscribe(cancelBtnResult => {
      if (!cancelBtnResult) {
        this.authService.logout();
      }
    });
  }

  protected readonly UserStatus = UserStatus;
}
